const initialState = {
    popupCTA: true,
    miniForm: false
}

const TOGGLE_POPUP_CTA = 'TOGGLE_POPUP_CTA'
const TOGGLE_MINI_FORM = 'TOGGLE_MINI_FORM'

export const togglePopupCTA = popupCTA => ({
    type: TOGGLE_POPUP_CTA, popupCTA
})
export const toggleMiniForm = miniForm => ({
    type: TOGGLE_MINI_FORM, miniForm
})

export default function App (state = initialState, action) {
    switch (action.type) {
        case TOGGLE_POPUP_CTA:
            return { ...state, popupCTA: action.popupCTA }
        case TOGGLE_MINI_FORM:
            return { ...state, miniForm: action.miniForm }
        default:
            return state
    }
}